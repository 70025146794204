export default class AppConfig {

    static get GCP_PROJECT_ID() {
        return "kujakuja-raiseyourvoice-live";
    }

    static get API_URL() {
        return "https://api.raiseyourvoice.kujakuja.com/api/v3/";
    }

    static get LOGIN_REQUIRED() {
        return true;
    }

    static get DEFAULT_LOGIN_EMAIL() {
        return "website@kujakuja.com";
    }

    static get DEFAULT_LOGIN_PASSWORD() {
        return "website_pass";
    }

    static get SITE_HEADER_DEFAULT_HIGHLIGHT_COLOUR() {
        return "#FFC300";
    }

    static get SITE_HEADER_DEFAULT_TITLE_TEXT() {
        return "Customer feedback";
    }

    static get GOOGLE_TRANSLATE_API_KEY() {
        return "AIzaSyBdb23jFxq3_CkU0cYpHLJUO1WknHu9YA8";
    }

    static get TOP_KEYWORDS_ENABLED() {
        return false;
    }

    static get TOP_KEYWORDS_LOGIN_URL() {
        return "https://kujakuja.co/login";
    }

    static get TOP_KEYWORDS_LOGIN_USERNAME() {
        return "";
    }

    static get TOP_KEYWORDS_LOGIN_PASSWORD() {
        return "";
    }

    static get TOP_KEYWORDS_API_URL() {
        return "";
    }

    static get DATA_STUDIO_GRAPH_URL() {
        return "https://datastudio.google.com/embed/reporting/43774207-f0b3-427a-9dc1-354683d3c3e7/page/p_e6qk72nmsc";
    }
}
